<template lang="pug">
  //- swiper
  .cursor-pointer(ref="cont", :style="{cursor: cursor}", @mousemove="onMousemove", @click="onClick")
    .relative.pb-120pct.md-pb-0.sm-h-screen
      //- slides...
      figure.absolute.overlay.bg-off-white.flex.flex-col(v-for="(slide, i) in slides", :class="{'opacity-0': active !== i, 'md-py-2': slide.fit === 'contain'}", v-if="renderImg(i)")
        .flex-1.relative.w-full.select-none
          img.absolute.overlay.object-center(:src="thumb(slide.image.url)", :alt="slide.image.alt", :class="[{'object-cover': slide.fit === 'cover', 'object-contain': slide.fit === 'contain'}]")
        figcaption.absolute.w-full.top-100.right-0.pr-20.pt-2.text-right.md-px-4.md-text-center.text-xxs.md-text-sm.min-h-10(v-html="slide.caption")
      //- counter
      .absolute.top-100.md-top-0.right-0.pr-4.pt-2.md-pr-3.text-xxs.md-text-sm {{active + 1}}/{{slides.length}}
</template>

<script>
// eslint-disable-next-line
import { thumb } from '@/utils'
import throttle from 'lodash/throttle'
export default {
  name: 'LightboxSlider',
  props: {
    slides: { type: Array, default: () => ([]) },
    startAt: { type: Number, default: 0 },
    theme: { type: String, default: 'black' },
  },
  data () {
    return {
      active: 0,
      swiper: null,
      cursor: null,
      // touch0: null
    }
  },
  methods: {
    thumb,
    next (dir = 1) {
      const [actv, total] = [this.active, this.slides.length]
      this.active = actv + dir < 0 ? total - 1 : actv + dir === total ? 0 : actv + dir
    },
    onClick (e) {
      if (e.clientX <= window.innerWidth * 0.5) {
        return this.next(-1)
      } else {
        return this.next()
      }
    },
    onMousemove: throttle(function (e) {
      this.cursor = e.clientX <= window.innerWidth * 0.5 ? 'w-resize' : 'e-resize'
    }, 200),
    renderImg (i) {
      return (i <= this.active + 2 && i >= this.active - 1) || (i === 0 && this.active === this.slides.length - 1)
    },
    // onTouchMove (e) {
    //   if (!this.touch0) return
    //   const t0 = this.touch0.changedTouches['0']
    //   const t1 = e.changedTouches['0']
    //   const dx = t1.clientX - t0.clientY
    //   const dy = t1.clientY - t0.clientY
    //   if (Math.abs(dy) <= 50 && Math.abs(dx) >= 150) {
    //     let dir = dx < 0 ? 1 : -1 // left/next, right/prev
    //     this.next(dir)
    //     this.touch0 = null
    //     console.log('go')
    //   }
    // }
  }
}
</script>

<style>
</style>
