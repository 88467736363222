<template lang="pug">
  #app
    carousel(:slides="slides", fit="cover", v-if="slides.length")
    section.pt-20.md-pt-36.px-6.md-px-16.pb-32.text-left.whitespace-pre-line.mx-auto.text-lg(v-if="channel", v-html="channel.metadata.description", style="max-width:44em;")
</template>

<script>
import './css/_main.css'
import carousel from '@/components/Fader'
export default {
  name: 'App',
  components: { carousel },
  data () {
    return {
      channel: null,
      blocks: []
    }
  },
  computed: {
    slides () {
      let blks = [...this.blocks]
      blks = blks.reverse()
      return blks
        .filter(blk => blk.image)
        .map(blk => ({
          image: {
            url: blk.image.original.url,
            alt: blk.description 
          },
          caption: blk.description_html,
          fit: 'contain'
        }))
    }
  },
  methods: {
    async get (slug) {
      try {
        let resp = await fetch(`https://api.are.na/v2/channels/${slug}?per=75`)
        resp = await resp.json()
        this.channel = resp
        this.blocks = resp.contents
      } catch (e) {
        console.error(e)
      }
    }
  },
  created () {
    this.get(process.env.VUE_APP_ARENA_CHANNEL)   
  }
}
</script>

<style>
html {
  font-size: 10px;
}

#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a{
  @apply underline;
}

.overlay{
  @apply w-full h-full top-0 left-0
}
</style>
